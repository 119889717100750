import client from "./client";

const getUsers = (filter) => {
    return client
        .get(`/users`, filter)
        .then((response) => {
            return response.data;
        })
        .catch((error) => error.response);
};


const getUserById = (id) => {
    return client
        .get(`/user/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => error.response);
};


const blockUser = (id) => {
    return client
        .get(`/auth/block/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => error.response);
};

const unblockUser = (id) => {
    return client
        .get(`/auth/unblock/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => error.response);
};
const userAPI = {
    getUsers,
    getUserById,
    blockUser,
    unblockUser,
};

export default userAPI;


