<template>
    <div>
        <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{ padding: 0, paddingTop: '16px' }">
            <div class="mx-25">
                <a-row type="flex" :gutter="24">
                    <a-col :span="24" class="text-right">
                        <a-input-search placeholder="input search text" style="max-width: 200px;" v-model="query"
                            @input="debouncedSearch" />
                    </a-col>
                </a-row>
            </div>
            <a-table class="mt-20" :columns="columns" :data-source="customers" rowKey="id"
                :pagination="{ pageSize: pageSize, current: currentPage, total: totalCount, onChange: handlePageChange }"
                :loading="isNotLoading">
                <template slot="name" slot-scope="text">{{ text }}</template>
                <template slot="action" slot-scope="action">
                    <a-button type="primary" @click="$router.push(`/users/edit/${action}`)">
                        <a-icon type="edit" />
                    </a-button>
                </template>
                <template slot="block" slot-scope="is_blocked">
                    <a-tag :color="getBlockedColor(is_blocked)" class="role-tag">
                        {{ getBlockLabel(is_blocked) }}
                    </a-tag>
                </template>
                <!-- <template slot="block" slot-scope="{ rowKey: id }"> -->
                <!--     <a-switch @change="(value) => handleBlockedChange(id, value)" /> -->
                <!-- </template> -->
            </a-table>
        </a-card>
    </div>
</template>
<script>
import debounce from 'lodash/debounce';
import userAPI from '../../api/user.js';
// Table columns
const columns = [
    {
        title: 'NAME',
        dataIndex: 'name',
        sorter: (a, b) => a.name > b.name ? 1 : -1,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'name' },
    },
    {
        title: 'EMAIL',
        dataIndex: 'email',
        sorter: (a, b) => a.email > b.email ? 1 : -1,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'email' },
    },
    {
        title: 'BLOCKED',
        dataIndex: 'is_blocked',
        scopedSlots: { customRender: 'block' },
    },
    {
        title: 'ACTION',
        dataIndex: 'id',
        scopedSlots: { customRender: 'action' },
    },
];

export default {
    data() {
        return {
            columns,
            customers: [],
            pageSize: 10,
            currentPage: 1,
            totalCount: 0,
            query: '',
            isNotLoading: false,
            debouncedSearch: () => { },

        }
    },
    methods: {
        getBlockedColor(is_blocked) {
            if (is_blocked) {
                return 'red';
            } else {
                return 'green';
            }
        },
        getBlockLabel(is_blocked) {
            if (is_blocked) {
                return 'Yes';
            } else {
                return 'Not'
            }
        },
        async handleBlockedChange(id, newValue) {
            console.log(`Customer ID: ${id}, is_blocked: ${newValue}`);
        },
        async handlePageChange(page) {
            this.currentPage = page;
            const data = await this.getUsers({
                params: {
                    "limit": this.pageSize,
                    "skip": (this.currentPage - 1) * this.pageSize,
                }
            })
            if (data.code == "SUCCESS") {
                if (data.data.customers) {
                    this.totalCount = data.data.count;
                    this.customers = data.data.customers;
                } else {
                    this.totalCount = 0;
                    this.customers = [];
                }
            }
            this.isNotLoading = false;
        },
        async handleBlockedChange(userId, isBlocked) {
            console.log(userId, isBlocked)
            try {
                if (isBlocked) {
                    const response = await userAPI.blockUser(userId);
                    if (response.success) {
                        this.$message.success('User blocked successfully.');
                    } else {
                        this.$message.error(response.message || 'Error blocking user.');
                    }
                } else {
                    const response = await userAPI.unblockUser(userId);
                    if (response.success) {
                        this.$message.success('User unblocked successfully.');
                    } else {
                        this.$message.error(response.message || 'Error unblocking user.');
                    }
                }
            } catch (error) {
                this.$message.error('An error occurred while updating user status.');
            }
        },

        async getUsers(filter) {
            this.isNotLoading = true;
            return userAPI.getUsers(filter);
        },

        async onSearchChange() {
            if (this.query.length >= 3) {
                const data = await this.getUsers({
                    params: {
                        "limit": this.pageSize,
                        "skip": 0,
                        "search": this.query,
                    }
                })
                if (data.code == "SUCCESS") {
                    if (data.data.customers) {
                        this.customers = data.data.customers;
                        this.totalCount = data.data.count;
                    } else {
                        this.totalCount = 0;
                        this.customers = [];
                    }
                }
                this.isNotLoading = false;
            } else {
                const originalData = await this.getUsers({
                    params: {
                        "limit": this.pageSize,
                        "skip": 0,
                    }
                });
                if (originalData.code == "SUCCESS") {
                    if (originalData.data.customers) {
                        this.customers = originalData.data.customers;
                        this.totalCount = originalData.data.count;
                    } else {
                        this.totalCount = 0;
                        this.customers = [];
                    }
                }
                this.isNotLoading = false;
            }
        },
    },
    async mounted() {
        this.debouncedSearch = debounce(this.onSearchChange, 300);
        const data = await this.getUsers({
            params: {
                "limit": this.pageSize,
                "skip": (this.currentPage - 1) * this.pageSize,
            }
        })
        if (data.code == "SUCCESS") {
            if (data.data.customers) {
                this.customers = data.data.customers;
                this.totalCount = data.data.count;
            } else {
                this.totalCount = 0;
                this.customers = [];
            }
        }
        this.isNotLoading = false;
    },
}

</script>
